<template>
  <a-modal :maskClosable="false" :zIndex='1060' dialogClass="translate-modal" :visible="visible" @cancel="handleCancel">
    <div @mouseenter="mousemove" @mouseleave="mouseout" slot="title" class="modal-title">
      错误类型
    </div>
    <div @mouseenter="mousemove" @mouseleave="mouseout" class="symbol-list">
      <div>
        <a-radio-group v-model="errorValue">
          <a-radio :style="radioStyle" v-for="errorItem in dictList" :key="errorItem.id" :value="errorItem.itemText">
            {{ errorItem.itemText }}
          </a-radio>
        </a-radio-group>
      </div>
    </div>
    <template slot="footer">
      <div @mouseenter="mousemove" @mouseleave="mouseout">
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="handleOk">
          完成
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import JEditor from '@/components/jeecg/JEditor'
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
export default {
  components: {
    JEditor
  },
  mixins: [UploadFileToOssMixins],
  props: {
    dictList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      errorValue: '',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
      },
      isEdit: false,
      isPlatte:false
    }
  },
  methods: {
    mousemove() {
      this.$emit('changeIsSelectedLocal', true)
    },
    mouseout() {
      this.$emit('changeIsSelectedLocal', false)
    },
    open(isPlatte) {
      this.visible = true
      this.isPlatte=isPlatte
    },
    edit(value) {
      this.visible = true
      this.errorValue = value
    },
    handleOk() {
      if (!this.errorValue) {
        this.$message.error('请选择错误类型')
        return
      }
      if(this.isPlatte){
        this.$emit('errorChangePlatte', this.errorValue)
      }else{
        this.$emit('errorChange', this.errorValue)
      }
      this.errorValue = ''
      this.visible = false
      this.isEdit = false
      this.isPlatte=false
    },
    handleCancel() {
      this.errorValue = ''
      this.visible = false
      this.isEdit = false
      this.isPlatte=false
      this.$emit('clearSelectErrorType')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .tox-statusbar {
  display: none !important;
}
/deep/ .mce-content-body img {
  max-width: 200px !important;
}
.modal-title {
  height: 36px;
  background-color: #ebebeb;
  padding: 2px 12px 2px 12px;
  line-height: 32px;
  font-weight: 400;
}
/deep/ .translate-modal .ant-modal-header {
  padding: 0 !important;
}
/deep/ .translate-modal .ant-modal-body {
  padding: 12px 24px;
}
/deep/ .translate-modal .ant-modal-close {
  height: 36px;
}
/deep/ .translate-modal .ant-modal-close-x {
  height: 36px;
  line-height: 36px;
}
.symbol-list {
  line-height: 32px;
  padding-bottom: 20px;
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar-button {
    height: 0;
  }
  &::-webkit-scrollbar {
    border-radius: 0;
    position: relative;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: #fff;
  }
  &::-webkit-scrollbar-track-piece {
    border-radius: 0;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #c1c1c1;
  }
  .show-img-bottom-left {
    margin-right: 3px;
  }
}
</style>
